.react-calendar {
  border: none !important;
  width: 100%;
}

.react-calendar__tile--active {
  background-color: #d90023 !important;
}

.react-calendar__tile--now {
  color: #000;
  border-radius: 20px;
}

.react-calendar__tile--rangeStart {
  border-radius: unset !important;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

.react-calendar__tile--rangeEnd {
  border-radius: unset !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.react-calendar__month-view__days__day {
  color: #000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__tile--active {
  color: #fff;
}

.react-calendar__tile--range {
  color: #fff;
  border-radius: 0;
}

.react-calendar__tile--hoverStart {
  border-radius: unset !important;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}

.react-calendar__tile--hoverEnd {
  border-radius: unset !important;
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.react-calendar__navigation__label__labelText {
  color: #000;
}

.react-calendar__navigation__arrow {
  color: #d90023;
  font-size: 32px;
}

.react-calendar__tile--hasActive {
  background-color: #d90023;
  border-radius: 20px;
}
.react-calendar__tile--range {
  background-color: #d90023;
}
.react-calendar__tile--rangeStart {
  background-color: #d90023;
}

.react-calendar__navigation__label__labelText {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

abbr[title] {
  text-decoration: none !important;
  border-bottom: none !important;
}

.react-calendar__tile--rangeBothEnds {
  border-radius: 20px !important;
}

.react-calendar__month-view__weekdays__weekday {
  color: #d90023;
  font-size: 17px;
  font-weight: 400;
}

.react-time-picker__clear-button {
  display: none !important;
}

.react-time-picker {
  width: calc(50% - 8px);
}

.react-time-picker__wrapper {
  background-color: #eaeaea;
  border: none !important;
  outline: none !important;
  padding: 16px 0;
  text-align: center;
}

.react-time-picker__inputGroup {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.react-time-picker__inputGroup__input {
  outline: none !important;
  background: none !important;
  caret-color: transparent !important;
}

.time-header {
  background-color: #d90023 !important;
}

.time-body > div > div > div > div:first-child {
  background-color: #d90023 !important;
}

.time-body > div > div > div {
  background-color: #d90023 !important;
}

.time-body > div > div > div > div:last-child {
  border: 16px solid #d90023 !important;
}

input {
  outline: none;
}

.MuiButton-label {
  color: #d90023;
}
